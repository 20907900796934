<template lang="pug">
  modal-editor.edit-asset-modal(:visible="visible"
                                :show-close="false"
                                recordType="Asset"
                                :record="record"
                                :buttons="['close', 'delete', 'save']"
                                :preparePayload="preparePayload"
                                :isRecordValid="isSubmittable"
                                :skipConfirmation="!isRecordDirty"
                                ref="modalEditor")
    template(v-slot:content)
      .field-container.img-uploader-container.is-required
        .image-title-header
          label Image
          aiq-checkbox(:model-value="isPublic" id="public" @change="onPublicCheckChange" :disabled="!isNewAssetDialog") Public
          aiq-tooltip.help-icon(placement="right")
            template(v-slot:content)
              div(v-html="'Check if the document can be shared publicly'")
            i.iq-ico-explanation(class="tooltip-help-icon")
        aiq-upload.img-uploader(v-model="imageUrl"
                                name="file"
                                :http-request="uploadImage"
                                :action="url"
                                accept="image/*"
                                :headers="headers"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess")
          aiq-Image(
            v-if="avatarUploaded || getRecordProp('file.payload.url', null)"
            :imageKey="getRecordProp('file.payload.key', null)"
            :src="getRecordProp('file.payload.url', null)"
            :acl="getRecordProp('file.payload.acl', IMAGE_ACL_TYPES.PUBLIC)"
            :bucket="getRecordProp('file.payload.bucket', null)")
          .img-uploader-add(v-else)

      .field-container.top-right.is-required
        label Title
        aiq-input(size="small"
                  v-model="record.title"
                  name="title")
      .field-container.top-right
        label URL
        aiq-input(size="small"
                  disabled,
                  :model-value="getRecordProp('file.payload.url', '')"
                  name="picture")
      .field-container.is-required(v-if="getRecordProp('file.payload.extension', false)")
        label Type
        aiq-input(size="small"
                  disabled
                  :model-value="getRecordProp('file.payload.extension', '')")
      .field-container.is-required(v-if="getRecordProp('file.payload.dimensions', false)")
        label Size
        aiq-input(size="small"
                  disabled
                  :model-value="getRecordProp('file.payload.dimensions', '')")
      .field-container.full-width
        label Description
        aiq-input(type="textarea"
                  placeholder="..."
                  v-model="record.description"
                  name="description")

      .field-container.full-width.is-required
        label Keywords
        .asset-keywords
          aiq-keyword-input(:list="record.keywords"
                            @createItem="addKeyword"
                            @deleteItem="deleteKeyword"
                            :deletable="true"
                            :itemMaxLength="keywordMaxLength"
                            v-model="record.keywords"
                            name="keywords")
</template>

<script>
import without from 'lodash/without';
import pick from 'lodash/pick';
import get from 'lodash/get';
import { hosts } from '@/config/api.routes';
import Editor from '@/components/Editor/Editor.vue';
import ModalEditor from '@/components/ModalEditor/ModalEditor.vue';
import { IMAGE_ACL_TYPES } from '@/constants';
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';

export default {
  name: 'assetEditor',
  extends: Editor,
  components: {
    ModalEditor,
  },
  setup () {
    return { v$: useVuelidate() };
  },
  validations () {
    return {
      imageUrl: { required, $autoDirty: true },
      record: {
        title: { required, $autoDirty: true },
        description: { $autoDirty: true },
        keywords: {
          required,
          minLength: minLength(1),
          $autoDirty: true,
        },
      },
    };
  },
  data() {
    return {
      // imageUrl is used by vee validate for validation
      imageUrl: '',
      keywordMaxLength: process.env.INPUT_PARAMS.keywordMaxLength,
      url: `${hosts.management}files/upload`,
      headers: {
        Authorization: this.$store.getters['agent/authToken'],
      },
      modelPath: 'assets/assetModel',
      selectAction: 'assets/selectAsset',
      fetchAction: 'assets/getAsset',
      avatarUploaded: false,
      IMAGE_ACL_TYPES,
      publicCheck: false,
    };
  },
  watch: {
    'record.file.payload.url': function handler(url) {
      // Assumes any truthy url is valid
      if (url) { this.imageUrl = url }
    },
  },
  computed: {
    isNewAssetDialog() {
      return !this.record.id;
    },
    isPublic() {
      if (!this.isNewAssetDialog) {
        return get(this.record, 'file.payload.acl', 'private') === IMAGE_ACL_TYPES.PUBLIC;
      }
      return this.publicCheck;
    },

    isRecordDirty() {
      return this.v$.$anyDirty;
    },

    isRecordValid() {
      return !this.v$.$invalid;
    },
  },
  methods: {
    preparePayload(record) {
      const r = record;
      delete r.picture;
      delete r.agents;

      return {
        ...r,
        file_id: r.file ? record.file.file_id : null,
      };
    },
    closeEditor() {
      this.visible = false;
    },
    addKeyword(keyword) {
      const existingKeywords = this.record.keywords;
      if (existingKeywords.includes(keyword)) {
        return;
      }
      this.record = {
        ...this.record,
        keywords: [...this.record.keywords, keyword],
      };
    },
    deleteKeyword(keyword) {
      this.record = {
        ...this.record,
        keywords: without(this.record.keywords, keyword),
      };
      if (!this.record.keywords.length) {
        return this.$aiq.notify.error('Asset must have at least 1 keyword.');
      }
    },
    handleAvatarSuccess(res) {
      this.avatarUploaded = false;
      this.record = {
        ...this.record,
        file: {
          payload: { ...res },
          file_id: res.file_id,
        },
      };

      this.avatarUploaded = true;
    },
    handleAvatarReset() {
      this.record = pick(this.record, ['title', 'keywords']);
      this.avatarUploaded = false;
      this.imageUrl = '';
    },
    onPublicCheckChange(value) {
      if (this.publicCheck !== value) {
        this.publicCheck = value;
        this.handleAvatarReset();
      }
    },

    uploadImage(data) {
      const formData = new FormData();
      formData.append('file', data.file);
      if (this.publicCheck) {
        formData.append('acl', IMAGE_ACL_TYPES.PUBLIC);
      }
      return axios
        .post(this.url,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((res) => res.data);
    },

  },
};
</script>

<style lang="scss" scoped>
.image-title-header {
  display: flex;
  width: 200px;
  height: 32px;
}

.is-required {
  .el-checkbox::before {
    margin-left: 10px;
    content: "" !important;
    color: #FF0000;
  }
}

.tooltip-help-icon {
  margin-left: 5px;
  margin-top: 3px;
}

.help-icon {
  margin-left: 5px;
}

</style>
