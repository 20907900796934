
<template lang="pug">
  management-page.scrollable(title="Communication"
                            :creatable="false"
                            :searchable="false"
                            :showHeader="false")
    template(v-slot:body)
      aiq-row(:gutter="16" justify="end")
        .time-range-filter
          aiq-date-picker(v-model="dateRange"
                          @change="handleDatePickerChange($event)"
                          type="daterange"
                          placeholder="Select date range",
                          popper-class="metrics-time-range__date-picker-popup"
                          :shortcuts="datePickerOptions.shortcuts"
                          :disabled-date="datePickerOptions.disabledDate"
                          :teleported="false"
                          format="YYYY/MM/DD"
                          :clearable="false")
      include ./selectableFilters/SelectableFilters.pug
      .chart-grid
        aiq-row(:gutter="16")
          aiq-col(:span="24")
            overview-chart(v-if="configs.cobrowse_volume"
                          :dateRange="dateRangeUtc"
                          :filters="selectedFilters"
                          :chartConfig="cobrowseVolumeConfig"
                          yLabel="Count"
                          :formatYTick="intCountFormatter"
                          visualization='aiq-bar-chart'
                          :reportFileName="returnReportFileName"
                          :formatXTick="formatXTick"
                          :getOriginalData="getOriginalData"
                          @onChartDataChange="onChartDataChange($event)"
                          @onChartBreakdownChange="chosenBucketOption=$event"
                          :useDebounceForLoadMetrics="true"
                          )
      p.footnote * Indicates partial dataset due to selected date range.

</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import OverviewChart from '@/components/Chart.vue';
import cobrowseVolumeConfig from '../../configs/cobrowwseVolume';
import PlatformFiltersMixin from './selectableFilters/SelectableFilters';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { datePickerDefaultOptions, getLastQuarterRange } from '../../configs/dateRange';
import cloneDeep from 'lodash/cloneDeep';

const createPickerDateRangeOption = option => ({
  text: option.label,
  value() {
    const range = [option.startTime(), option.endTime()];
    return range;
  },
});
const DATE_RANGE_OPTIONS = [
  {
    label: 'Last Month',
    startTime: () => moment()
      .subtract(1, 'month')
      .startOf('month'),
    endTime: () => moment()
      .subtract(1, 'month')
      .endOf('month'),
  },
  {
    label: 'Last Quarter',
    ...getLastQuarterRange(),
  },
];

export default {
  pageResource: '/metrics/platform',
  name: 'Communication',
  mixins: [PlatformFiltersMixin],
  components: {
    ManagementPage,
    OverviewChart,
  },
  mounted() {
    try {
      this.loadAllFilterOptions();
    } catch (err) {
      this.$aiq.notify.error(`Failed: ${err.message}`);
    }
  },
  watch: {
    dateRange: debounce(function onDateChange(newValue, oldValue = []) {
      const [start, end] = oldValue;
      if (!(start && end)) {
        return;
      }
      this.loadAllFilterOptions();
    }, 1),
  },
  
  data() {
    return {
      cobrowseVolumeConfig,
      intCountFormatter: v => (Math.floor(parseFloat(v)) === parseFloat(v) ? v : null),
      chartData: [],
      chosenBucketOption: null,
      xLabels: {},
      formatXTick: (date) => {
        return this.xLabels[moment(date).valueOf()] + `${this.isDataPartial(date) ? ' *' : ''}` ;
      },
      getOriginalData: () => {
        return this.originalData;
      }
    };
  },
  methods:{
    onChartDataChange(data) {
      this.chartData = data?.dataset?.find(item=>item?.metric === this.cobrowseVolumeConfig.metric)?.data_points;
      // store the original data for this use case
      // on the date picker if the user picks 8/1/2024 to 8/30/2024 and chooses weekly from the granuality dropdown
      // this will result in bars for 8/1, 8/4, 8/11, 8/18, & 8/25
      // 8/1 and 8/4 will be too close to each because of how d3 renders the chart
      // we save the original data so when the user downloads the data, the dates are correct
      // for rendering the chart we change the values in the time series to 7/28, 8/4, 8/11, 8/18, & 8/25 so the bars are spaced correctly.
      // in a separate object we store the labels as 8/1, 8/4, 8/11, 8/18, & 8.25 so the x-axis values coincide with the dates chosen by the datepicker.
      // this.xLabels represents the labels for the x-axis.
      this.originalData = cloneDeep(data);
      this.chartData.forEach((data_point) => {
        let value = data_point.x;
        let label = moment(data_point.x).format('MMM DD');
        if (this.chosenBucketOption?.value === 'week') {
          value = moment(data_point.x).day('Sunday').valueOf();
          // change the x value for the time series so the bars get space correctly.
          data_point.x = value;
        } else if (this.chosenBucketOption?.value === 'month'){
          label = moment(data_point.x).format('MMM');
        }
        this.xLabels[value] = label;
      });

    },
    isDataPartial(date) {
      const timeStamp = new Date(date).getTime();
      const isPartial = this.chartData.find(item=> item.x === timeStamp)?.isPartial;
      return isPartial;
    },
  },
  computed: {
    ...mapGetters({
      configs: 'configs/graphsConfigs',
    }),
    ...mapState({
      showCobrowse: state => state.featureFlags.FEATURE_FLAGS.showCobrowse,
    }),
    datePickerOptions() {
      return {
        ...datePickerDefaultOptions(),
        shortcuts: DATE_RANGE_OPTIONS.map(createPickerDateRangeOption),
      };
    },
    returnReportFileName(){
      const [startTimestamp, endTimestamp] = this.dateRange;
      const startDate = moment.isMoment(startTimestamp)
        ? startTimestamp.format('YYYY-MM-DD')
        : moment(startTimestamp).format('YYYY-MM-DD');
      const endDate = moment.isMoment(endTimestamp)
        ? endTimestamp.format('YYYY-MM-DD')
        : moment(endTimestamp).format('YYYY-MM-DD');
      return `communication_${startDate}_${endDate}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/aiq-variables.scss";
@import "./selectableFilters/SelectableFilters.scss";

.scrollable {
  overflow-y: auto;
}

.el-card:not(:first-of-type) {
  margin-top: 24px;
}

.time-range-filter {
  position: relative;
  margin-bottom: 24px;
}


.chart-configuration {
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
  .breakdown {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  .members-filter {
    display: flex;
    align-items: center;
  }

  .export-command {
    display: flex;
    height: 30px;
    width: 40px;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    padding: 0;
  }
}
.footnote{
  color: #8492A6;
  font-size: 14px;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
</style>
