<template lang="pug">
  include ManagementPage.pug
</template>

<script>
import { Component, Vue, toNative } from 'vue-facing-decorator';
import moment from 'moment';
import debounce from 'lodash/debounce';
import kebabCase from 'lodash/kebabCase';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import {
  simplifyQueryParams,
  expandQueryParams,
  getAsyncOptionContainers,
  adjustUrlArray,
} from '@/libs';
import { QUERY_MAPPING } from '@/constants';
import { mapState } from 'vuex';

const SCROLL_OFFSET_FROM_BOTTOM = 2;
const PSEUDO_OPTION = { field: 'pseudo-field', label: 'All', selected: true };
const SEARCH_DEBOUNCE_MS = 1000;
const WHEEL_TIMEOUT_MS = 300;

@Component({
  name: 'managementPage',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    resetOffsetToDefaultOnMount: {
      type: Boolean,
      required: false,
    },
    classPrefix: {
      type: String,
    },
    FeedBackOptions: {
      type: Array,
      default: () => [
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' },
      ],
      // TODO (akshay) get from a config
    },
    defaultFeedBackScoreShown: {
      type: Array,
      default: [],
    },
    creatable: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    exportable: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    searchPageSize: {
      type: Number,
      default: 20,
    },
    customerAgentFilter: {
      type: Boolean,
      default: false,
    },
    customerAgentOptions: {
      type: Array,
      default: () => [],
    },
    customerChannelFilter: {
      type: Boolean,
      default: false,
    },
    customerChannelOptions: {
      type: Array,
      default: () => [],
    },
    teamFilter: {
      type: Boolean,
      default: false,
    },
    teamOptions: {
      type: Array,
      default: () => [],
    },
    customerSegmentFilter: {
      type: Boolean,
      default: false,
    },
    customerSegmentOptions: {
      type: Array,
      default: () => [],
    },
    customerProfileFilter: {
      type: Boolean,
      default: false,
    },
    customerProfileOptions: {
      type: Array,
      default: () => [],
    },
    openFilters: {
      type: Boolean,
    },
    customerAgentDefault: {
      type: String,
      default: '',
    },
    showDatePicker: {
      type: Boolean,
    },
    datePickerOptions: {
      type: Array,
      default: () => [],
    },
    preFilterText: {
      type: String,
      default: '',
    },
    searchBoxPlaceHolder: {
      type: String,
      default: '',
    },
    loadWithQuery: {
      type: String,
      default: '',
    },
    info: {
      type: String,
    },
    pagination: {
      type: Object,
      default: {
        offset: 0,
        limit: 0,
        rowCount: 0,
      },
    },
    showTotals: {
      type: Boolean,
      defaut: false,
    },
    showLoadAll: {
      type: Boolean,
      defaut: false,
    },
    showDownloadButton: {
      type: Boolean,
      default: false,
    },
    showFeedBackFilter: {
      type: Boolean,
      default: false,
    },
    isDataLoading: {
      type: Boolean,
      default: false,
    },
    filteredSearch: {
      type: Boolean,
      default: false,
    },
    creationTooltipContent: {
      type: String,
      default: '',
    },
    disabledDatePickerRange:{
      type: Function,
      default: () => false,
    }
  },
  emits: [
    'forwardChangedScores',
    'fetchData',
    'openEditor',
    'export',
    'removePreFilter',
    'loadAll',
    'downloadData',
    'stopDataLoading',
  ],
  computed: {
    ...mapState({
      isMobileContext: state => state.settings.isMobileContext,
    }),
    customerProfileOptionsList() {
      return [
        PSEUDO_OPTION,
        ...this.customerProfileOptions,
      ];
    },
    getFetchDataValues() {
      const customerProfileOption = this.customerProfileOption?.field === 'pseudo-field' ? null : this.customerProfileOption;
      const dataValues = {
        searchTerm: this.query,
        searchOffset: this.offset,
        dateRange: this.dateRange,
        datePickerOption: this.datePickerOption,
        customerAgentOption: this.customerAgentOption,
        filterScore: this.chosenScores,
        customerChannelOption: this.customerChannelOption,
        teamOption: this.teamOption,
        customerSegmentOption: this.customerSegmentOption,
        customerProfileOption,
      };
      const validQuery = simplifyQueryParams(
        { query: this.query, ...dataValues },
        this.$route.path,
      );
      const currentUrlQuery = this.$route.query;
      if (!isEqual(currentUrlQuery, validQuery) && has(QUERY_MAPPING, this.$route.path)) {
        this.$router.push({ query: validQuery });
      }
      return dataValues;
    },
    totalResults() {
      return this.pagination.rowCount;
    },
    visibleResults() {
      return Math.min(this.pagination.offset + this.pagination.limit, this.pagination.rowCount);
    },
    isAllDataLoaded() {
      return this.totalResults === this.visibleResults;
    },
    placeholder() {
      if (this.customerProfileFilter) {
        return `Search by ${this.customerProfileOption.label}`;
      }
      return this.searchBoxPlaceHolder || `Search ${this.title}`;
    },
    areFilterOptionsLoaded() {
      return !getAsyncOptionContainers(this.$route.path).reduce(
        (accumulator, currentValue) => accumulator && isEmpty(this[currentValue]),
        true,
      );
    },
    pageClassName() {
      return `${kebabCase(this.classPrefix)}`;
    },
    searchId() {
      return `${kebabCase(this.classPrefix)}-search`;
    },
  },
  watch: {
    pagination() {
      this.shouldFetchMoreData();
    },
    // eslint-disable-next-line no-unused-vars
    resetOffsetToDefaultOnMount(newValue, oldValue) {
      if (newValue === true) {
        this.offset = this.searchPageSize;
      }
    },

    query(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchWithDebounce();
      }
    },
    dateRange(newValue, oldValue) {
      if (newValue !== oldValue) { this.fetchWithDebounce() }
    },
    datePickerOption(newValue, oldValue) {
      if (newValue !== oldValue) { this.fetchWithDebounce() }
    },
    customerAgentOption(newValue, oldValue) {
      if (newValue !== oldValue) { this.fetchWithDebounce() }
    },
    customerChannelOption(newValue, oldValue) {
      if (newValue !== oldValue) { this.fetchWithDebounce() }
    },
    teamOption(newValue, oldValue) {
      if (!isEqual(sortBy(newValue), sortBy(oldValue))) { this.fetchWithDebounce() }
    },
    customerSegmentOption(newValue, oldValue) {
      if (!isEqual(sortBy(newValue), sortBy(oldValue))) { this.fetchWithDebounce() }
    },
    customerProfileOption(newValue, oldValue) {
      if (newValue !== oldValue && this.query) { this.fetchWithDebounce() }
    },
    dateStart() {
      this.onChangeStartEndDatesMobile();
    },
    dateEnd() {
      this.onChangeStartEndDatesMobile();
    },
    areFilterOptionsLoaded(newValue) {
      if (newValue) {
        const stateQuery = expandQueryParams(this);
        if (!isEmpty(stateQuery)) {
          Object.entries(stateQuery).forEach(([queryParam, queryVal]) => {
            this[queryParam] = queryVal;
          });
        }
      }
    },
  },
  methods: {
    onChangeStartEndDatesMobile() {
      let dateStart = '';
      let dateEnd = '';
      if (this.dateStart) {
        dateStart = this.dateStart;
      }
      if (this.dateEnd) {
        dateEnd = moment(this.dateEnd).add(23, 'h').add(59, 'm').add(59, 's'); // eslint-disable-line no-magic-numbers
      }
      this.dateRange = [dateStart, dateEnd];
    },
    updateSearchTerm(searchTerm) {
      this.query = searchTerm;
    },
    onFeedBackScoreChange(scoreObjects) {
      this.chosenScores = scoreObjects.map(scoreObject => scoreObject.name);
      this.$emit('forwardChangedScores', scoreObjects);
    },
    resetAndFetchData() {
      this.offset = 0;
      this.fetchData();
    },
    fetchData() {
      if (this.query !== this.lastQuery) {
        this.offset = 0;
      }
      this.$emit('fetchData', this.getFetchDataValues);
      this.lastQuery = this.query;
      this.offset += this.searchPageSize;
    },

    shouldFetchMoreData() {
      if (this.tableBody) {
        const hasVerticalScrollbar = this.tableBody.scrollHeight > this.tableBody.clientHeight;
        if (!hasVerticalScrollbar && !this.isAllDataLoaded) {
          this.fetchData();
        }
      }
    },

    openEditor() {
      this.$emit('openEditor');
    },

    exportPage() {
      this.$emit('export');
    },

    removePreFilter() {
      this.$emit('removePreFilter');
      this.fetchWithDebounce();
    },

    onDataLoading() {
      this.$emit('loadAll');
    },

    onDownloadData() {
      this.$emit('downloadData');
    },

    onStopDataLoading() {
      this.$emit('stopDataLoading');
    },

    hidePopper(event) {
      const elements = document.getElementsByClassName('el-popper');
      for (let i = 0; i < elements.length; i += 1) {
        if (elements[i].style.display !== 'none') {
          const { screenX, screenY, clientX, clientY, relatedTarget } = event;

          // Send mousedown event
          let clickEvent = new MouseEvent('mousedown', {
            bubbles: true,
            cancelable: true,
            view: window,
            detail: 1,
            screenX,
            screenY,
            clientX,
            clientY,
            button: 0,
            relatedTarget,
          });
          event.target.dispatchEvent(clickEvent);

          // Send mouseup event
          clickEvent = new MouseEvent('mouseup', {
            bubbles: true,
            cancelable: true,
            view: window,
            detail: 1,
            screenX,
            screenY,
            clientX,
            clientY,
            button: 0,
            relatedTarget,
          });
          event.target.dispatchEvent(clickEvent);
          return;
        }
      }
    },

    handleScroll(event) {
      if (!this.ticking) {
        window.requestAnimationFrame(() => {
          this.hidePopper(event);
          this.ticking = false;
        });
        this.ticking = true;
      }
    },

    handleWheel() {
      if (this.wheelTimer) {
        clearTimeout(this.wheelTimer);
      }
      this.wheelTimer = setTimeout(() => {
        this.wheelTimer = 0;
      }, WHEEL_TIMEOUT_MS);
    },
  },
})
class ManagementPage extends Vue {
  offset = 0;
  lastQuery = null;
  tableBody = null;
  ticking = false;
  wheelTimer = 0;
  handleScrollBind = null;
  handleWheelBind = null;

  dateRange = '';
  dateStart = '';
  dateEnd = '';
  customerChannelOption = null;
  customerProfileOption = PSEUDO_OPTION;
  customerSegmentOption = [];
  teamOption = [];
  query = '';
  customerAgentOption = '';
  datePickerOption = '';

  mounted() {
    this.query = this.loadWithQuery;
    this.customerAgentOption = this.customerAgentDefault;
    this.datePickerOption = this.datePickerOptions[0] || '';
    this.tableBody = this.$el.querySelector('.el-scrollbar__wrap');
    if (this.tableBody) {
      const DEBOUNCE_TIME_MS = 100;
      this.tableBody.addEventListener(
        'scroll',
        debounce(({ target }) => {
          const scrolledToBottom = target.scrollHeight - target.scrollTop
            - SCROLL_OFFSET_FROM_BOTTOM <= target.clientHeight;
          if (scrolledToBottom && !this.isAllDataLoaded) {
            this.fetchData();
          }
        }, DEBOUNCE_TIME_MS),
      );
    }

    this.fetchWithDebounce = debounce(
      this.resetAndFetchData,
      SEARCH_DEBOUNCE_MS,
      { trailing: true, leading: false },
    );

    if (isEmpty(this.$route.query)) {
      this.fetchWithDebounce();
    } else {
      adjustUrlArray(this.$route.path, this.$route.query);
    }

    const elements = document.getElementsByClassName('el-tabs__content');
    this.handleScrollBind = this.handleScroll.bind(this);
    this.handleWheelBind = this.handleWheel.bind(this);
    for (let i = 0; i < elements.length; i += 1) {
      elements[i].addEventListener('scroll', this.handleScrollBind);
      elements[i].addEventListener('wheel', this.handleWheelBind);
    }
  }

  beforeUnmount() {
    const elements = document.getElementsByClassName('el-tabs__content');
    for (let i = 0; i < elements.length; i += 1) {
      elements[i].removeEventListener('scroll', this.handleScrollBind);
      elements[i].removeEventListener('wheel', this.handleWheelBind);
    }
    this.fetchWithDebounce.cancel();
  }
}
export default toNative(ManagementPage);
</script>

<style lang="scss" scoped>
  @import "./ManagementPage.scss";
  .select-box-container {
          width: 200px;
          height: 32px;
      }
</style>

<style lang="scss">
@import "./../../styles/aiq-variables.scss";
@import "./../../styles/media-queries.scss";

.content-inner {
  @include mobile {
    .el-date-range-picker{
      min-width: 120px !important;
    }
    .mobile-date-picker > .el-input__inner  {
      border: 1px solid $aiq-icon-primary;
    }
  }

  .header {
    .teams-filter * > input,
    .customer-profile-filter > * input,
    .customer-segment-filter > * input {
      height: 30px !important;
    }
  }

  .more-filters {
    span {
      margin-top: -1px;
      line-height: 30px !important;
    }
  }
}


</style>
