import { Component, Vue, toNative } from 'vue-facing-decorator';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import map from 'lodash/map';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import { INGESTION_MODES } from '@/constants';
import { confirmBeforeDialogClose } from '@/libs';

const INFO = 'Displays ingested records.';

@Component({
  name: 'Import',
  components: {
    ManagementPage,
  },
  computed: {
    ...mapGetters({ RECORD_STATE: 'ingestion/RECORD_STATE' }),
    ...mapState({
      records: state => state.ingestion.records,
      mode: state => state.ingestion.mode,
    }),
    stateOptions() {
      return Object.values(this.RECORD_STATE)
        .sort()
        .map(val => ({ label: upperFirst(val), value: val }));
    },
  },
  methods: {
    ...mapActions({
      loadImportedData: 'ingestion/loadImportedData',
      resolveConflict: 'ingestion/resolveConflict',
      ingestFile: 'ingestion/ingestFile',
    }),
    ...mapMutations({
      setMode: 'ingestion/SET_MODE',
    }),
  },
})

class Import extends Vue {
  // File Path
  modes = INGESTION_MODES;
  selectedState = 'failure';
  uploadDialogShown = false;
  uploadDialogConfirmationShown = false;
  fileNameToUpload = '';
  inUploadingProgress = false;
  fileNotSelected = true;
  file = null;
  shouldOverride = false;

  pagination = {
    offset: 0,
    limit: 20,
    rowCount: 0,
    pageCount: 0,
  }

  created() {
    this.$_info = INFO;
  }

  mounted() {
    this.load();
  }

  async load() {
    try {
      const res = await this.loadImportedData({
        state: this.selectedState,
        pagination: this.pagination,
      });
      this.pagination = res.pagination;
    } catch (err) {
      // Unable to load data
      this.$aiq.notify.error('Unable to fetch data.');
    }
  }

  async sendResolveConflict(recordId, resolution) {
    try {
      await this.resolveConflict({ ...recordId, resolution });
      this.$aiq.notify.success('The record has been resolved.');
    } catch (err) {
      this.$aiq.notify.error('Unable to resolve the conflict.');
    }
  }

  async onUploadChange(data) {
    this.fileNameToUpload = get(data, 'name', '');
    this.file = data.raw;
    this.fileNotSelected = false;
  }

  onChangeState(newState) {
    this.selectedState = newState;
    this.pagination = {
      offset: 0,
      limit: 20,
      rowCount: 0,
      pageCount: 0,
    };
    this.load();
  }

  clearLocalData() {
    this.fileNameToUpload = '';
    this.file = null;
    this.fileNotSelected = true;
    this.shouldOverride = false;
  }

  async upload() {
    this.inUploadingProgress = true;
    if (this.file) {
      try {
        const ingestion = await this.ingestFile({ data: this.file, override: this.shouldOverride });
        const ingestion_error = get(ingestion, 'failure', []);
        if (!ingestion_error.length) {
          this.$aiq.notify.success(this.$t('settings_tab.import_tab.upload_alert.ingestion_success', { filename: this.fileNameToUpload }));
        } else {
          this.$aiq.notify.error(this.$t('settings_tab.import_tab.upload_alert.ingestion_error', { filename: this.fileNameToUpload }));
        }
      } catch (err) {
        const errors = get(err, 'response.data.errors', []);
        if (errors.length) {
          const errorString = errors[0].reason;
          this.$aiq.notify.error(errorString);
        } else {
          const error = get(err, 'response.data');
          const errorMsg = error || this.$t('settings_tab.import_tab.upload_alert.upload_failed', { filename: this.fileNameToUpload });
          this.$aiq.notify.error(errorMsg);
        } 
      }
    }
    this.inUploadingProgress = false;
    this.uploadDialogConfirmationShown = false;
    this.$nextTick(() => {
      this.uploadDialogShown = false;
    });

    this.clearLocalData();
    await this.load();
  }

  async onPageChanged(page) {
    this.pagination.offset = this.pagination.limit * (page - 1);
    await this.load();
  }

  csvExport() { // eslint-disable-line
    const csvContent = this.mode.headings.join(',');
    const data = `data:text/csv;charset=utf-8,${encodeURI(csvContent)}`;
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', `${this.mode.label} template.csv`);
    link.click();
  }

  toCSV(obj) { // eslint-disable-line
    return map(obj, (value) => value).join(', ');
  }

  openUploadConfirmationDialog() {
    if (this.shouldOverride){
      this.uploadDialogShown = false;
      this.uploadDialogConfirmationShown = true;
    } else {
      this.upload();
    }
  }
  openUploadDialog() {
    this.uploadDialogShown = true;
    this.uploadDialogConfirmationShown = false;
  }

  handleImportCsvModalBeforeClose(){
    // The default value for mode is the first value of modes
    // Therefore if they're not equal, it means user has changed it
    const userHasUnsavedInfo = this?.file || this.modes?.[0].label !== this.mode?.label;
    if (userHasUnsavedInfo) {
      confirmBeforeDialogClose(this, this.closeImportCsvModal);
    } else {
      this.closeImportCsvModal();
    }
  }
  closeImportCsvModal(){
    this.uploadDialogShown = false;
    this.uploadDialogConfirmationShown = false;
    this.clearLocalData();
    this.setMode(this.modes?.[0]);
  }
}
export default toNative(Import);
