import { Component, Vue, toNative } from 'vue-facing-decorator';
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import has from 'lodash/has';
import isEqual from 'lodash/isEqual';
import aiqFunctionBox from './AIQFunctionBox/AIQFunctionBox.vue';
import {
  makeDependencyAlertMsg,
  commandsParamsTypes,
  allCommandsBySystemName,
} from '@/libs';

const { mapGetters } = createNamespacedHelpers('configs');
const defaultFunction = {
  command_name: '',
  params: { },
};

@Component({
  name: 'aiqCommandBuilder',
  props: {
    command: {
      type: Object,
      default() {
        return {
          name: '',
          payload: {
            custom_name: '',
            description: '',
            functions: [{ ...defaultFunction }],
          },
        };
      },
    },
  },
  emits: ['deleted', 'cancel'],
  data() {
    return {
      allCommandsBySystemName,
      name: get(this.command, 'name', ''),
      customName: get(this.command, 'payload.custom_name', ''),
      description: get(this.command, 'payload.description', ''),
      functions: cloneDeep(this.command.payload.functions),
      functionBoxIdCounter: this.getCounter(),
    };
  },
  watch: {
    command(newCommand) {
      this.name = get(newCommand, 'name', '');
      this.customName = get(newCommand, 'payload.custom_name', '');
      this.description = get(newCommand, 'payload.description', '');
      this.functions = cloneDeep(newCommand.payload.functions);
    },
  },
  computed: {
    ...mapGetters([
      'iqtoolsCommands',
    ]),
    deleteFunctionAllowable() {
      return this.functions.length > 1;
    },
    isNew() {
      return !has(this.command, 'id');
    },
    functionEntries() {
      const functions = [];
      this.functions.forEach(func => {
        functions.push({
          command_name: func.command_name,
          params: func.params,
        });
      });
      return functions;
    },
    isNewForm() {
      return !this.command.id;
    },
    isChanged() {
      if (this.isNewForm) {
        return (this.functions.length > 0) && (this.functions[0].command_name)
          && (this.customName !== '');
      }

      return (this.name !== get(this.command, 'name', ''))
          || (this.customName !== get(this.command, 'payload.custom_name', ''))
          || (this.description !== get(this.command, 'payload.description', ''))
          || (!isEqual(this.functions, get(this.command, 'payload.functions', '')));
    },
  },
  components: {
    aiqFunctionBox,
  },
})

class AIQCommandBuilder extends Vue {
  addFunctionBox() {
    this.functionBoxIdCounter += 1;

    const { functionBoxIdCounter } = this;
    const newFunction = { ...defaultFunction, localFunctionId: functionBoxIdCounter };
    this.functions.push(newFunction);
  }

  getCounter() {
    if (!this.command.payload.functions || this.command.payload.functions === []) {
      return 0;
    }

    return this.command.payload.functions.length - 1;
  }

  mounted() {
    this.$refs.titleInput.focus();
  }
  prepareFunctions() {
    if (!this.command.payload.functions || this.command.payload.functions === []) {
      return [{ ...defaultFunction, localFunctionId: 0 }];
    }

    return this.command.payload.functions.map((func, i) => ({ ...func, localFunctionId: i }));
  }

  updateFunctionName(info) {
    const index = info.localFunctionId;
    this.functions[index].command_name = info.selectedName;
    this.functions[index].params = {};
  }

  updateParams(info) {
    const index = info.localFunctionId;
    if (this.functions[index].command_name) {
      const systemName = this.functions[index].command_name;
      const { paramsType } = this.allCommandsBySystemName[systemName];
      const { toEntry } = commandsParamsTypes[paramsType];

      this.functions[index].params = toEntry(info.selectedParams);
    }
  }

  remove(index) {
    this.functions.splice(index, 1);
  }

  deleteCommand() {
    this.$aiq.confirm(
      'Delete Command',
      'Do you really want to delete this command?',
    ).then(
      () => {
        this.$store.dispatch('buildingKit/deleteAction', this.command.id)
          .then(() => {
            this.$aiq.notify.info('Command has been deleted.');
            this.$emit('deleted');
          })
          .catch(res => {
            const { title, content } = makeDependencyAlertMsg('Command', res, {$t: this.$t});
            this.$aiq.confirm(title, content);
          });
      },
      () => { },
    );
  }

  save() {
    const entry = {
      name: this.name || 'actions.run',
      payload: {
        custom_name: this.customName,
        description: this.description,
        functions: this.functionEntries,
      },
    };

    const actionToPerform = this.isNewForm ? 'buildingKit/createAction' : 'buildingKit/updateAction';
    const payload = this.isNewForm ? entry : [this.command.id, entry];

    return this.$store.dispatch(actionToPerform, payload)
      .then(() => {
        const action = this.isNewForm ? 'created' : 'updated';
        this.$aiq.notify.success(`Command has been ${action}.`);

        if (this.isNewForm) {
          this.resetForm();
        }

        // Used to close the form
        this.$emit('cancel');
      });
  }

  resetForm() {
    this.customName = get(this.command, 'payload.custom_name', this.command.name);
    this.description = get(this.command, 'payload.description', '');
    this.functions = cloneDeep(this.command.payload.functions);
  }

  cancel() {
    this.resetForm();
    this.$emit('cancel', this.command.id);
  }
}
export default toNative(AIQCommandBuilder);
